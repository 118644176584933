import '@nlux/themes/nova.css';
import './custom-nova-theme.css';
import { IPublicClientApplication } from '@azure/msal-browser';
import { Route, Routes, useNavigate } from 'react-router-dom';
import React from 'react';
import { CustomNavigationClient } from './utils/NavigationClient';
import { MsalProvider } from '@azure/msal-react';
import { HomePage } from './Component/HomePage';
import AIChatView from './Component/AIChatView';
import { AuthChecker } from './Component/AuthChecker';

type AppProps = {
  pca: IPublicClientApplication;
};

const App = ({ pca }: AppProps) => {
  const navigate = useNavigate();
  React.useEffect(() => {
    const setupMsal = () => {
      const navigationClient = new CustomNavigationClient(navigate);
      pca.setNavigationClient(navigationClient);
      console.log("Setting up MSAL");
    }
    setupMsal();
  }, [navigate, pca]);

  return (
    <MsalProvider instance={pca}>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='AIChat' >
          <Route path=':id' element={<AIChatView />} />
        </Route>
        <Route path='AuthChecker' element={<AuthChecker />} />
      </Routes>
    </MsalProvider>
  );
};

export default App;
