import '@nlux/themes/nova.css';
import '../custom-nova-theme.css';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import ChatSubView from './ChatSubView';
import { AuthContext } from '../authConfig'
import { useState } from 'react';

const AIChatView = () => {
    const isMsalLogin = useIsAuthenticated();
    const initLogin = AuthContext.isLogin(isMsalLogin);
    const [isLogin, setIsLogin] = useState(initLogin);

    window.tokenUpdated = () => {
       const tempLogin = AuthContext.isLogin(isMsalLogin);
       setIsLogin(tempLogin);
    }

    if (!isLogin) {
        return (<></>)
    }
    return (<ChatSubView />);
};

export default AIChatView;