import {Adapter, AdapterExtras} from '@nlux/react';
import { IMsalContext } from '@azure/msal-react';
import { JSEvent, MessageInput } from '../Models/ChatModels';
import { AIChatApi } from './AIChatApi';
import { forEachChild } from 'typescript';

class OpenAIContext {
    constructor(){}

}

interface DAVMAIFetchAdapter extends Adapter {
    assisantId?: string;
    threadId?: string;
    msal?: IMsalContext | null | undefined;
    api?: AIChatApi;
}

export const myCustomPromiseAdapter: DAVMAIFetchAdapter = {

    fetchText(question: string, extras: AdapterExtras): Promise<string> {
        console.log(`AI info ${this.assisantId}, ${this.threadId}`);
        return new Promise((resolve) => {
            try
            {
                const input: MessageInput = { role: 'user', content: question};
                this.api?.askQuestion(this.threadId ?? "", this.assisantId ?? "", input).then(values => {
                    values.forEach((msg) => {
                        console.log(`${msg.role} returns ${msg.content.length}`);
                        msg.content.forEach((content) => {
                            console.log(`${msg.role} returns ${content.text.value}`);
                            var data: JSEvent = {  eventType: 'JSEvent/TextToSpeech', data: content.text.value}
                            try
                            {
                                window.invokeCSharpAction(JSON.stringify(data));
                            } catch (e) {
                                console.log(e);
                            }
                            resolve(content.text.value);
                        }
                    );
                    })
                });
            }
            catch (e) {
                console.log(e);
                resolve("API Error");
            }
        });
    },
};
