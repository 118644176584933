import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { AuthContext, loginRequest } from "../authConfig";
import { Button } from "@fluentui/react-components";
import { useState } from "react";
import { AIChatApi } from "../Services/AIChatApi";



export const AuthChecker = () => {
    const temp = AuthContext.isLogin(useIsAuthenticated());
    const [isLogin, setIsLogin] = useState(temp);
    const [messages, setMessages] = useState<string[]>([]);

    const msal = useMsal();

    const clearMessages = () => {
        messages.length = 0;
        let newArr = [...messages]
        setMessages(newArr);
    }

    const signin = async () => {
        await msal.instance.loginRedirect(loginRequest);
    }

    const signout = async () => {
        await msal.instance.logoutRedirect();
    }

    const getAccessToken = async () => {
        const token = await msal.instance.acquireTokenSilent(loginRequest);
        if (token) { 
            messages.unshift('New token from MSAL');
        }
        let newArr = [...messages]
        setMessages(newArr);
    }

    const setHostSetToken = async () => {
        const token = await msal.instance.acquireTokenSilent(loginRequest);
        window.sessionStorage.setItem("apiAccessToken", token.accessToken); 
        window.sessionStorage.setItem('tokenExpiry', token.expiresOn ? token.expiresOn.toISOString() : '');
        console.log("add token to session store");
        messages.unshift('add token to session store');
        window.tokenUpdated();
        let newArr = [...messages]
        setMessages(newArr);
    }

    window.tokenUpdated = () => {
        const temp = AuthContext.isLogin(isLogin);
        messages.unshift('New token is from host app');
        console.log('New token is from host app');
        setIsLogin(temp); 
        let newArr = [...messages]
        setMessages(newArr);
    }

    const api = new AIChatApi(msal);

    const callAPi = async () => {
        var result = await api.getAssistant('asst_W1y1vJgELTJQeU3Jq0M3IneD');
        if (result) {
            messages.unshift('getAssistant API call return result');
        } else {
            messages.unshift('getAssistant return error');
        }
        let newArr = [...messages]
        setMessages(newArr);
    }


    return (
        <div className="container">
            <div>Is Login: {isLogin ? 'Yes' : 'No'}</div>
            <div><Button onClick={(e) => signin()}>Login Using MSAL</Button></div>
            <div><Button onClick={(e) => signout()}>Logoff MSAL</Button></div>
            <div><Button onClick={(e) => getAccessToken()}>Get Access Token</Button></div>
            <div><Button onClick={(e) => setHostSetToken()}>Login Using Host App</Button></div>
            <div><Button onClick={(e) => callAPi()}>Call API</Button></div>
            <div><Button onClick={(e) => clearMessages()}>Clear Messages</Button></div>
            {messages.map((item, key) => { 
                return (<div key={key}>{item}</div>)
            })}
        </div>)

}