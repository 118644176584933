import { BrowserCacheLocation, Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "445c414f-7533-49e5-a935-7a63e6426e22",
        authority: "https://login.microsoftonline.com/d07183f5-0384-4262-a031-1aded9e39b41",
        redirectUri: window.location.origin + '/',
        postLogoutRedirectUri: "/"
    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: [ "api://445c414f-7533-49e5-a935-7a63e6426e22/user_impersonation" ]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

export const apiConfig = {
    apiEndpoint: "https://dfvam-api-dev.azurewebsites.net",
    openAPIEndpoint: "wss://dvam.azurewebsites.net/api/OpenAIStream"
};

export const AuthContext = {
    isLogin: (isMsal: boolean) => {
        if (isMsal) return true;
        const apiToken = window.sessionStorage.getItem("apiAccessToken");
        if (apiToken) return true;
        return false;
    }
}