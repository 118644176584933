import { Button } from "@fluentui/react-components";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { Link } from "react-router-dom";
import { APIStatusCheckPage } from "./APIStatusCheckPage";



export const HomePage = () => {
  const msal = useMsal();

  const loggOff = async () => {
    await msal.instance.logout(loginRequest);
  }

  const doLogin = () => {
    msal.instance.loginRedirect(loginRequest);
  }

  const testCSAction = () => {
    window.invokeCSharpAction('hello world');
  }

  const isAuth = useIsAuthenticated() || window.sessionStorage.getItem('apiAccessToken');

  if (isAuth) {
    return (
      <div>
        <h2>Please choose an AI Assistant</h2>
        <div>Powered by TheFrontline.App</div>
        <div><Link to="AIChat/asst_W1y1vJgELTJQeU3Jq0M3IneD"><h2 className="AssistentLabel" >QLD FDV Assistant (asst_W1y1vJgELTJQeU3Jq0M3IneD)</h2></Link></div>
        <div><Link to="AIChat/asst_r0Oac11gWzoh2QF25ANPRmmT"><h2 className="AssistentLabel">WA Firearms Assistant (asst_r0Oac11gWzoh2QF25ANPRmmT)</h2></Link></div>
        <APIStatusCheckPage />

        <Button onClick={() => { loggOff() }}>Log Off</Button>
      </div>)
  } else {
    return (
      <div>
        <Button onClick={(e) => doLogin()}>Login</Button>
        <Button onClick={(e) => testCSAction()}>Test CS Action</Button>
      </div>)
  }
};